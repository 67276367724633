import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
//LoginSignup and ForgotPassword Modal
import Splash from "../layout/Splash";
import LoginRegister from '../layout/LoginRegister';
//api
import connect from 'react-redux/es/connect/connect';
import { activateUser } from '../../api/LoginSignupApi';
import { getCurrentLocation } from '../../functions'
import { enablesetting } from '../../redux/actions/onBoardingActions';
class HomeView extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isLogin: false,
            showSplash: true
        }
    }

    componentDidMount() {
        let search = window.location.search;
        const { signIn } = window?.history?.state?.state || {};
        let params = new URLSearchParams(search);
        // if (!localStorage.getItem("zaccesstoken")) {
        //     const urlParams = new URLSearchParams(window.location.hash.substring(1))
        //     const accessToken = urlParams.get("access_token")
        //     if (accessToken) localStorage.setItem("zaccesstoken", accessToken)
        //     if(window?.opener?.location) window.opener.location.reload(true);
        //     if(window?.close()) window.open("", "zendeskAuthWindow").close()
        // }
        let key = params.get('key');
        let referalcode = params.get('referalcode');
        let invitedCode = params.get('invitecode')
        if (key != null) activateUser(key);
        if (referalcode) localStorage.setItem('referalcode', referalcode);
        if (invitedCode) localStorage.setItem('invitecode', invitedCode);
        signIn ? this.checkLogin(false) : this.checkLogin("");
        signIn && window.history.replaceState({}, document.title)
        if (referalcode) this.setState(prev => ({ ...prev, showSplash: false, isLogin: false }))
    }

    checkLogin = (action) => {
        if (action !== "") {
            getCurrentLocation();
            localStorage.setItem('profile_image', process.env.PUBLIC_URL + "/images/user-icon.svg")
            this.setState({ isLogin: action, showSplash: false });
            if (action) {
                localStorage.setItem("Default", process.env.REACT_APP_KEY)
                this.props.history.push('/dashboard')
            }
        }
        else if (localStorage.getItem('id') !== null && localStorage.getItem('token') !== null) {
            this.props.history.push('/dashboard')
        }
        else if (localStorage.getItem('Default') !== null) {
            this.props.history.push('/dashboard')
        } else {
            this.setState({ isLogin: false, showSplash: true });
        }
    }


    render() {
        return (
            <>
                {this.state.showSplash ?
                    <Splash checkLogin={this.checkLogin} />
                    : <div className="wrapper">
                        {!this.state.isLogin &&
                            <LoginRegister checkLogin={this.checkLogin} />
                        }
                    </div>
                }
            </>
        )
    }
}

const mapActionsToProps = {
    enablesetting
}


export default connect(null, mapActionsToProps)(withRouter(HomeView));